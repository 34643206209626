require('../css/_page_homepage.scss');

var $ = require('jquery');

$(document).ready(function() {
    $("#js-scroll-down").on("click", function (event) {
        event.preventDefault();

        var top = $('#programs').offset().top;
        $('body,html').animate({
            scrollTop: top
        }, 1000);
    });

    $('.share-icon').on('click', function(e){
        e.preventDefault();
        $(this).siblings('.sharing-container').fadeIn(300);
        $(this).fadeOut(300);
    });
});
